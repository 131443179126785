<template>
  <div
    class="sb-negative-targeting-keyword-table card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div>
      <component
        :is="customActionPanelConfigs.component"
        v-if="showCustomActionPanel"
        v-bind="customActionPanelConfigs.props"
        v-on="customActionPanelConfigs.events"
      />
    </div>
    <div
      v-if="selections.length"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
      @widgetParamsChanged="widgetParamsChanged"
    />
  </div>
</template>
<script>
import {
  deepReplaceObjectValues,
  findDimensionValueFromWidgetParams
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

export default {
  components: {
    tableWrapper,
    actionsBar
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      defaultSearchText: '',
      selections: [],
      updateMetadata: null,
      tableGridAPI: null,
      context,
      actionIdMethodMap: {
        makeKeywordArchive: this.makeKeywordArchive,
        makeTargetArchive: this.makeTargetArchive
      },
      widget: {},
      widgets: null,
      allowedActions: null,
      tabConfig: null,
      showCustomActionPanel: false
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    },
    targetingType() {
      if (this.entityConfig.entityType === 'SB') {
        let targetingType = 'negativeKeyword';
        if (this.$route.path.includes('product_adgroup')) {
          targetingType = 'negativeTarget';
        }
        return targetingType;
      }
      // negativeKeyword, negativeTarget
      return this.tabMetadata.targetingType === 'keyword'
        ? 'negativeKeyword'
        : 'negativeTarget';
    },
    customActionPanelConfigs() {
      if (this.targetingType) {
        return this?.tabConfig[this?.targetingType].customActionPanelComponent(
          this
        );
      }
    },
    headerOptions() {
      const that = this;
      const obj = {};
      if (this.tabMetadata?.widgets[0]) {
        obj.adgroup_sb_tab_negativekeywordtargeting = {
          props: {
            headerOptions: [
              {
                ui_component: 'rb-button',
                props: {
                  clickFn: that.addCustomProductsButtonClick,
                  text: 'Add Custom Negative Keywords',
                  type: 'filled'
                },
                class: 'u-spacing-mr-xl'
              }
            ],
            enableDownload: false,
            enableManageColumns: !this.selections.length,
            manageColumnGridPosition: 4,
            headerContainerGridStyles: {
              'grid-template-columns': 'auto 1fr auto',
              display: this.selections.length ? 'none' : 'grid'
            },
            searchParams: {
              show: true,
              value: this.defaultSearchText,
              placeholder: 'Find a Negative keyword',
              searchDimension: 'ams_cm_negativetargets_keyword_text'
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 45
          }
        };
        obj.adgroup_sb_tab_negativeproducttargeting = {
          props: {
            headerOptions: [
              {
                ui_component: 'rb-button',
                props: {
                  clickFn: that.addCustomProductsButtonClick,
                  text: 'Add Custom Negative Products',
                  type: 'filled'
                },
                class: 'u-spacing-mr-xl'
              }
            ],
            enableDownload: false,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto',
              display: this.selections.length ? 'none' : 'flex'
            },
            searchParams: {
              show: true,
              value: this.defaultSearchText,
              placeholder: 'Find a Negative Product',
              searchDimension: 'ams_cm_negativetargets_keyword_text'
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 45
          }
        };
        if (this.tabConfig?.getHeaderOptions) {
          obj[this.tabMetadata?.widgets[0]] =
            this.tabConfig?.getHeaderOptions(that);
        }
      }
      return obj;
    },
    getAdGroupInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    }
  },
  created() {
    Vue.component('iconTextActions', iconTextActions);
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.init();
    this.widget = this.widgets[this.tabMetadata.widgets[0]];
    this.allowedActions = tabConfig[this.targetingType].allowedActions;
  },
  methods: {
    widgetParamsChanged(value) {
      const searchText = findDimensionValueFromWidgetParams(
        value,
        this.tabMetadata?.widgets?.[0],
        'ams_cm_asin_search_text'
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      this.actionIdMethodMap[actionId]();
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.showCustomActionPanel = false;
    },
    makeKeywordArchive(state = 'archived') {
      const payloadArr = [];
      for (const selection of this.selections) {
        if (selection?.data?.ams_cm_negativetargets_state === state) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(
            this.tabConfig.negativeKeyword.stateChange.requestPayload
          );
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_negativetargets_state,
            ':state': state,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_negativetargets_keyword_text || 'NA',
            ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
            ':matchType':
              selection?.data?.ams_cm_negativetargets_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        const entityName = {
          singular: 'Negative Keyword Status',
          plural: 'Negative Keyword Statuses',
          stopFetchApi: true
        };
        this.updateMetadataAction(payloadArr, entityName);
      } else {
        this.closeActionBar();
      }
    },
    makeTargetArchive(state = 'archived') {
      const payloadArr = [];
      for (const selection of this.selections) {
        if (selection?.data?.ams_cm_negativetargets_state === state) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(
            this.tabConfig[this.targetingType].stateChange.requestPayload
          );
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_negativetargets_state,
            ':state': state,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_negativetargets_keyword_text || 'NA',
            ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
            ':matchType':
              selection?.data?.ams_cm_negativetargets_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        const entityName = {
          singular: 'Negative Keyword Status',
          plural: 'Negative Keyword Statuses',
          stopFetchApi: true
        };
        this.updateMetadataAction(payloadArr, entityName);
      } else {
        this.closeActionBar();
      }
    },
    callAddNegativeKeyword(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(
          this.tabConfig?.negativeKeyword?.addNegativeKeywordRequestPayload
        );
        let matchType = null;
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':pageUrl': window.location.href,
          ':newKeywordText': selection.recommended_keyword,
          ':matchType': selection?.match_type,
          ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
          ':viewPayloadKeywordText': selection.recommended_keyword
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Negative Keyword',
          plural: 'Negative Keywords',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    callAddNegativeTargets(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(
          this.tabConfig[this.targetingType].addNegativeTargetRequestPayload
        );
        const expressionType = selection?.categoryId
          ? 'asinCategorySameAs'
          : 'asinSameAs';
        const target = selection?.categoryId
          ? selection?.categoryId
          : selection?.competitor_asin;
        const targetView = selection.categoryId
          ? selection?.category
          : selection?.competitor_asin;
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':pageUrl': window.location.href,
          ':newKeywordText':
            this.targetingType === 'negativeTarget'
              ? target
              : selection.recommended_keyword,
          ':matchType':
            this.targetingType === 'negativeTarget'
              ? 'TARGETING_EXPRESSION'
              : selection.matchTypeToShow,
          ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
          ':viewPayloadKeywordText':
            this.targetingType === 'negativeTarget'
              ? targetView
              : selection.recommended_keyword,
          ':expressionType': expressionType // asinSameAs, asinCategorySameAs
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Negative Target',
          plural: 'Negative Targets',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName
      });
      this.closeActionBar();
      this.showCustomActionPanel = false;
    },
    addCustomProductsButtonClick() {
      this.closeActionBar();
      this.showCustomActionPanel = true;
    },
    init() {
      this.widgets = {
        adgroup_sb_tab_negativekeywordtargeting: {
          ':widget': 'adgroup_sb_tab_negativekeywordtargeting',
          ':orderByList': [],
          ':dimensionNameValueList': [],
          keepExistingDimensionNameValueList: true,
          replaceEntityInDimensionNameValueList: {
            ':adgroup_id': this.entityId
          },
          replaceEntityInOperationsObject: {
            ':system': `campaign_${this.parentEntityId}`
          }
        },
        adgroup_sb_tab_negativeproducttargeting: {
          ':widget': 'adgroup_sb_tab_negativeproducttargeting',
          ':orderByList': [],
          ':dimensionNameValueList': [],
          keepExistingDimensionNameValueList: true,
          replaceEntityInDimensionNameValueList: {
            ':adgroup_id': this.entityId
          },
          replaceEntityInOperationsObject: {
            ':system': `campaign_${this.parentEntityId}`
          }
        }
      };
      if (this.tabConfig?.getWidget) {
        const widgetFromConfig = this.tabConfig?.getWidget(
          this.entityId,
          this.tabMetadata
        );
        if (widgetFromConfig) {
          this.widgets[this.tabMetadata.widgets[0]] = widgetFromConfig;
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
.sb-negative-targeting-keyword-table .inputClassForEntityTaggingSearch {
  display: flex;
  align-items: center;
}
</style>
